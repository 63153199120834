<template>
  <v-container>
    <v-row class="row search spacer-md">
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-masterdata ref="search-masterdata" @search="search" />
      </v-col>
    </v-row>
    <v-btn color="primary" @click="search"><span v-html="$t('generic_search')" /></v-btn>
    <v-btn color="secondary" @click="reset"><span v-html="$t('generic_reset')" /></v-btn>
    <!-- <v-btn color="secondary" :loading="downloading" @click="download" ><span v-html="$t('generic_download')"/></v-btn> -->

    <v-data-table-server
      :headers="fields"
      :items="items"
      :fields="fields"
      :loading="loading"
      :items-length="totalElements"
      :multi-sort="true"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      :items-per-page-options="$itemsPerPageOptions"
      density="compact"
    >
      <template v-slot:[`item.agateId`]="{ item }">
        <template v-if="item.userCount > 1">
          <router-link
            :to="{
              name: 'applicationUsers_read',
              query: {
                size: 50,
                term: 'LegalEntity_uid:' + (item.legalEntityUid || '')
              }
            }"
          >
            <span v-html="$t('masterdata_many_users')"></span>
          </router-link>
        </template>
        <template v-else>
          {{ item.agateId }}
          <span class="link" v-if="$privileges.has({ path: '/userSwitch', permission: 'read' }) && item.applicationUserId" @click="userSwitch(item)"
            ><v-icon right>mdi-chevron-right</v-icon></span
          >
        </template>
      </template>

      <template v-slot:[`item.legalEntityName1`]="{ item }">
        <span :class="item.farmerStatus === ('active' || null) ? '' : 'inactive-cell'">
          {{ item.legalEntityName1 }}
        </span>
      </template>

      <template v-slot:[`item.legalEntityName2`]="{ item }">
        <span :class="item.farmerStatus === ('active' || null) ? 'l' : 'inactive-cell'">
          {{ item.legalEntityName2 }}
        </span>
      </template>

      <template v-slot:[`item.zip`]="{ item }">
        <span :class="item.farmerStatus === ('active' || null) ? '' : 'inactive-cell'">
          {{ item.zip }}
        </span>
      </template>

      <template v-slot:[`item.locality`]="{ item }">
        <span :class="item.farmerStatus === ('active' || null) ? '' : 'inactive-cell'">
          {{ item.locality }}
        </span>
      </template>

      <template v-slot:[`item.legalEntityUid`]="{ item }">
        <span>
          <router-link
            :to="{
              name: 'masterdata_legal_entities_edit',
              params: { id: item.legalEntityId, tab: 'overview' }
            }"
            v-if="item.legalEntityId"
            :class="item.farmerStatus === ('active' || null) ? ' ' : 'inactive-cell'"
          >
            {{ item.legalEntityUid && item.legalEntityUid.length > 0 ? item.legalEntityUid : $t('none') }}
          </router-link>
          <span class="link" v-if="$privileges.has({ path: '/domainSwitch', permission: 'read' })" @click="switchDomain(item)"
            ><v-icon right>mdi-chevron-right</v-icon></span
          >
        </span>
      </template>

      <template v-slot:[`item.bur`]="{ item }">
        <template v-if="item.apiRoute === 'dairies' && item.localUnitId">
          <router-link
            :to="{
              name: 'masterdata_dairies_edit',
              params: { id: item.localUnitId, tab: 'overview' }
            }"
            :class="item.localUnitStatus === ('active' || null) ? 'tableaction link' : 'inactive-cell'"
          >
            {{ item.bur && item.bur.length > 0 ? item.bur : $t('none') }}
          </router-link>
        </template>
        <template v-if="item.apiRoute === 'farms'">
          <router-link
            :to="{
              name: 'masterdata_farms_edit',
              params: { id: item.localUnitId, tab: 'overview' }
            }"
            :class="item.localUnitStatus === ('active' || null) ? 'tableaction link' : 'inactive-cell'"
          >
            {{ item.bur && item.bur.length > 0 ? item.bur : $t('none') }}
          </router-link>
        </template>
      </template>

      <template v-slot:[`item.dairyIdent`]="{ item }">
        <template v-if="$privileges.has({ path: '/dairies', permission: 'write' }) && item.localUnitId">
          <router-link
            :to="{
              name: 'masterdata_dairies_edit',
              params: { id: item.localUnitId, tab: 'overview' }
            }"
            :class="item.localUnitStatus === ('active' || null) ? 'tableaction link' : 'inactive-cell'"
          >
            {{ item.dairyIdent }}
          </router-link>
        </template>
        <template v-else>
          <td :class="item.localUnitStatus === ('active' || null) ? '' : 'inactive-cell'">
            {{ item.dairyIdent }}
          </td>
        </template>
      </template>

      <template v-slot:[`item.legalEntityAgisId`]="{ item }">
        <template v-if="$privileges.has({ path: '/legalEntities', permission: 'write' }) && item.legalEntityId">
          <router-link
            :to="{
              name: 'masterdata_legal_entities_edit',
              params: { id: item.legalEntityId, tab: 'overview' }
            }"
            :class="item.farmerStatus === ('active' || null) ? 'tableaction link' : 'inactive-cell'"
          >
            {{ item.legalEntityAgisId }}
          </router-link>
        </template>
        <template v-else>
          <td :class="item.farmerStatus === ('active' || null) ? '' : 'inactive-cell'">
            {{ item.legalEntityAgisId }}
          </td>
        </template>
      </template>

      <template v-slot:[`item.agisId`]="{ item }">
        <template v-if="item.apiRoute == 'dairies' && $privileges.has({ path: '/dairies', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_dairies_edit',
              params: { id: item.localUnitId, tab: 'overview' }
            }"
            :class="item.localUnitStatus === ('active' || null) ? 'tableaction link' : 'inactive-cell'"
          >
            {{ item.agisId ? item.agisId : $t('none') }}
          </router-link>
        </template>
        <template v-if="item.apiRoute == 'dairies' && !$privileges.has({ path: '/dairies', permission: 'write' })">
          <td :class="item.localUnitStatus === ('active' || null) ? '' : 'inactive-cell'">
            {{ item.agisId ? item.agisId : $t('none') }}
          </td>
        </template>
        <template v-if="item.apiRoute == 'farms' && $privileges.has({ path: '/farms', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_farms_edit',
              params: { id: item.localUnitId, tab: 'overview' }
            }"
            :class="item.localUnitStatus === ('active' || null) ? 'tableaction link' : 'inactive-cell'"
          >
            {{ item.agisId ? item.agisId : $t('none') }}
          </router-link>
        </template>
        <template v-if="item.apiRoute == 'farms' && !$privileges.has({ path: '/farms', permission: 'write' })">
          <td :class="item.localUnitStatus === ('active' || null) ? '' : 'inactive-cell'">
            {{ item.agisId ? item.agisId : $t('none') }}
          </td>
        </template>
      </template>
      <template v-slot:[`item.info`]="{ item }">
        <span :class="item.localUnitStatus === ('active' || null) ? '' : 'inactive-cell'">
          {{ item.info }}
        </span>
      </template>

      <template v-slot:[`item.localUnitStatus`]="{ item }">
        <span :class="item.localUnitStatus === ('active' || null) ? '' : 'inactive-cell'">
          {{ item.localUnitStatus === ('active' || null) ? $t('search_local_unit_active') : $t('search_local_unit_inactive') }}
        </span>
      </template>

      <template v-slot:[`item.farmerStatus`]="{ item }">
        <span :class="item.farmerStatus === ('active' || null) ? '' : 'inactive-cell'">
          {{ item.farmerStatus === ('active' || null) ? $t('search_legal_entity_active') : $t('search_legal_entity_inactive') }}
        </span>
      </template>
    </v-data-table-server>
  </v-container>
</template>
<script lang="ts">
import { apiURL } from '@/main'
//import { Term } from '@/services/term'
import searchMasterdata from '@/components/searchCards/searchMasterdata.vue'
import _ from 'lodash'
//import { privileges } from '@/services/privileges'
import { showError } from '@/services/axios'
import { fileCreator } from '@/services/axios'
import { personsService } from '@/services/persons'
import { DTSHeader } from '@/services/BackendService'
import { useSearchMasterdataStore } from '@/store/SearchMasterdataStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'masterdata_find',
  components: {
    searchMasterdata
  },
  data() {
    return {
      items: [],
      totalElements: 0,
      loading: false,
      downloading: false,
      fields: <DTSHeader[]>[
        // Agate-Nr
        {
          title: this.$t('generic_table_header_agate_id'),
          key: 'agateId',
          sortable: true,
          align: 'left'
        },
        // Name / Firma
        {
          title: this.$t('generic_table_header_name_company'),
          key: 'legalEntityName1',
          sortable: true
        },
        // Vorname / Zusatz
        {
          title: this.$t('generic_table_header_name_addition'),
          key: 'legalEntityName2',
          sortable: true
        },
        // PLZ
        {
          title: this.$t('generic_table_header_zip'),
          key: 'zip',
          sortable: true,
          align: 'left'
        },
        // Ort
        {
          title: this.$t('generic_table_header_locality'),
          key: 'locality',
          sortable: true
        },
        // Info
        {
          title: this.$t('generic_table_header_info'),
          key: `info${this.$getUpLang()}`,
          sortable: true
        },
        // UID
        {
          title: this.$t('generic_table_header_uid'),
          key: 'legalEntityUid',
          sortable: true,
          align: 'left'
        },
        // BUR
        {
          title: this.$t('generic_table_header_ber'),
          key: 'bur',
          sortable: true,
          align: 'left'
        },
        // MBH-ID
        {
          title: this.$t('generic_table_header_mbh_id'),
          key: 'dairyIdent',
          sortable: true,
          align: 'left'
        },
        // AGIS-ID Person
        {
          title: this.$t('generic_table_header_agis_id_person'),
          key: 'legalEntityAgisId',
          sortable: false,
          align: 'left'
        },
        // AGIS-ID Betrieb
        {
          title: this.$t('generic_table_header_agis_id_farm'),
          key: 'agisId',
          align: 'left',
          sortable: false
        },
        // TVD
        {
          title: this.$t('generic_table_header_tvd_id'),
          key: 'tvdId',
          sortable: true
        },
        // BLW Kreditoren-Nr.
        {
          title: this.$t('generic_table_header_sap_no'),
          key: 'sapNo',
          sortable: true
        },
        // Status Lokale Einheit
        {
          title: this.$t('generic_table_header_local_unit_status'),
          key: 'localUnitStatus',
          sortable: true
        },
        // Status Rechtliche Einheit
        {
          title: this.$t('generic_table_header_farmer_status'),
          key: 'farmerStatus',
          sortable: true
        }
      ]
    }
  },
  computed: {
    term(): any {
      return []
    },
    params() {
      return {
        name: useSearchMasterdataStore().name,
        address: useSearchMasterdataStore().address,
        ident: useSearchMasterdataStore().ident,
        contact: useSearchMasterdataStore().contact,
        farms: useSearchMasterdataStore().farms,
        dairies: useSearchMasterdataStore().dairies,
        legalEntities: useSearchMasterdataStore().legalEntities
      }
    },
    /* query for axios-calls and route-pushes */
    query(): any {
      return {
        ...this.params,
        ...this.getJavaPageOptions({ sort: ['localUnitStatus,asc', 'farmerStatus,asc', 'legalEntityName1,asc'] })
      }
    }
  },
  methods: {
    async load() {
      /* backend binding */
      this.loading = true
      try {
        this.items = []
        const response = await this.axios.get(apiURL + '/masterdata/find', {
          params: this.query
        })
        const items = await response.data.content
        this.items = items
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async download() {
      this.downloading = true
      try {
        const response = await this.axios.get(apiURL + '/masterdata/search', {
          params: this.query,
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(await response, 'stammdaten.xlsx')
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.downloading = false
      }
    },
    search() {
      this.$router
        .push({
          name: 'masterdata_find',
          query: this.query
        })
        .catch((e) => {
          // route duplicated
          this.load()
        })
    },
    reset() {
      this.$refs['search-masterdata'].reset()
    },
    async switchDomain(legalEntity) {
      personsService.switchDomain(legalEntity)
    },
    async userSwitch(item) {
      await personsService.impersonateAsUser(item)
    }
  },
  watch: {
    '$route.query': {
      handler() {
        this.load()
      },
      deep: true
    }
  },
  mounted() {
    /* do not load when view is mounted after click on menu/nav */
    if (this.$route.query.size) this.load()
  }
})
</script>
